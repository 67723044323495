import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax';
import Splide from "@splidejs/splide";

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit, AfterViewInit {

  clientSlider: Splide;
  constructor(private renderer: Renderer2) {  }

  ngOnInit(): void {
    var header = document.getElementById("header");
    header.classList.remove('page-nav');

    // Init slider
    this.clientSlider = new Splide('#clinet-slider', {
      type: 'loop',
      arrows: false,
      pagination: false,
      perPage: 6,
      autoplay: true,
      breakpoints: {
        600: {
          perPage: 2,
        }
      }
    }).mount();

    // jarallaxVideo();
    // jarallax(document.querySelectorAll('.cover-video'));
    jarallaxElement();

    var offsetfooter = document.getElementById('footer').offsetHeight;
    document.body.style.marginBottom = offsetfooter - 5 + "px";

    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
      disableParallax: /iPad|iPhone|iPod|Android/,
      disableVideo: /iPad|iPhone|iPod|Android/
    });
  }

  ngAfterViewInit() {
    // jarallaxVideo();
    // jarallax(document.querySelectorAll('.cover-video'));
    jarallaxElement();

      // setTimeout(() => {
      //   let loader = this.renderer.selectRootElement('#loader');
      //   this.renderer.setStyle(loader, 'display', 'none');
      // }, 1000)

    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
      disableParallax: /iPad|iPhone|iPod|Android/,
      disableVideo: /iPad|iPhone|iPod|Android/
    });
  }
}
