import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import Splide from '@splidejs/splide';
import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax';

@Component({
  selector: 'app-header-slider',
  templateUrl: './header-slider.component.html',
  styleUrls: ['./header-slider.component.scss']
})
export class HeaderSliderComponent implements OnInit, AfterViewInit {

  slider: Splide
  statuetteSettings = {
    scrollDistance: 600,
    frameCount: 8,
    pixelsPerFrame: 75,
    currentFrame: 1,
  }
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.slider = new Splide('#header-video-slider', {
      type: 'fade',
      rewind: true,
      pagination: true,
      perPage: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 1000,
      interval: 7000,
      autoplay: true,
      arrows: false,
      padding: 0,
      gap: 0,
      classes: {
        pagination: 'splide__pagination pagination_container',
        page: 'splide__pagination__page pagination_page'
      }
    });

    document.addEventListener('scroll', e => {
      this.statuetteSettings.currentFrame = Math.ceil(window.pageYOffset / this.statuetteSettings.pixelsPerFrame);
      if (this.statuetteSettings.currentFrame === 0) this.statuetteSettings.currentFrame++;
      if (this.statuetteSettings.currentFrame > this.statuetteSettings.frameCount) this.statuetteSettings.currentFrame = this.statuetteSettings.frameCount;
      // document.querySelector('.statuette').src = `assets/images/statuette/${currentFrame}.png`
    })
  }

  ngAfterViewInit() {
    this.slider.mount();

    jarallaxVideo();
    jarallax(document.querySelectorAll('.cover-video'));
    // jarallaxElement();
  }


}
