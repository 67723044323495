import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AudioService} from "../../audio.service";

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit, OnDestroy {

  // tracks = [
  //   'assets/sounds/6.mp3',
  //   'assets/sounds/7.mp3',
  //   'assets/sounds/4.mp3'
  // ]
  currentTrack: number;
  // audio = new Audio();
  audioPaused = true;

  constructor(private audioService: AudioService) { }

  ngOnInit(): void {
    this.currentTrack = this.audioService.currentTrack;
    this.audioPaused = this.audioService.audio.paused;
  }

  toggleMusic() {
    // this.audio.paused ? this.audio.play() : this.audio.pause()
    this.audioPaused = this.audioService.toggleMusic();
  }

  changeTrack(trackNumber: number): void {
    // this.audio.pause();
    // this.audio.src = this.tracks[trackNumber]
    // this.audio.load()
    // this.currentTrack = trackNumber
    // this.audio.play()
    this.audioService.changeTrack(trackNumber);
    this.currentTrack = this.audioService.currentTrack;
    this.audioPaused = false;
    console.log(this.currentTrack);
  }

  ngOnDestroy() {
    // this.audio.pause();
    // this.audioService.audio.pause();
  }
}
