<section style="padding: 0;" class="header-section">

  <app-controls></app-controls>

  <div class="splide" id="header-video-slider">
    <div class="splide__track">

      <div class="splide__list">

        <!-- SLIDE 1 -->
        <div class="splide__slide">
          <div class="splide__slide__container">
            <div class="cover-video"
                 data-background="assets/videos/walking_man_snap-min.png"
                 data-jarallax-video="mp4:assets/videos/walking_man_1.mp4"
                 style="min-height: 105vh; z-index: 0; background-image:url('assets/videos/walking_man_snap-min.png')">
              <div class="container-xl inner-content">
                <div class="row top-heading mobile-heading">
                  <div class="col-sm-12 col-xl-12 mt-0 mt-xl-5">
                    <h1 class="mt-5 mb-3"><strong>Produkujemy <span>skuteczne</span><br> rozwiązania biznesowe.<br>Digital <span>mamy</span> w DNA</strong></h1>
                    <!--                        <p class="col-12 col-xl-5"><a class="btn btn-lg btn-block btn-light" href="#">Gatting Started Today</a></p>-->
                    <div class="col-12 col-xl-7 pr-2" style="visibility: hidden">
                      <p class="col-12 col-sm-8 col-md-5 col-xl-6"><a class="btn btn-lg btn-block" [routerLink]="['/ebenefit']">Czytaj więcej ></a></p>
                    </div>
                  </div>
                </div>
                <app-top-services class="d-xxl-none"></app-top-services>
              </div>
              <div class="overlay-background"></div>
            </div>
          </div>
        </div>
        <!-- END SLIDE 1 -->

        <!-- SLIDE 2 with prize -->
        <div class="splide__slide" style="">
          <div class="cover-video"
               data-jarallax-video="mp4:assets/videos/nagroda_krakdent.mp4"
               style="min-height: 105vh; z-index: 0;">
            <div class="container-xl inner-content">
              <div class="row top-heading mobile-heading">
                <div class="col-sm-12 col-xl-8 mt-0 mt-xl-5">
                  <h1 class="mt-5 mb-3"><strong>Nasz produkt <span>Eminto</span> zdobywcą <span>najważniejszych</span> nagród w branży stomatologicznej.</strong></h1>
                </div>
                <div class="d-none col-sm-12 col-xl-4 d-xl-flex justify-content-center align-items-center pt-5">
<!--                    <div class="d-flex justify-content-center align-items-center">-->
                      <img [src]="'assets/images/nagrody.png'" alt="nagrody_eimto" class="img-fluid">
<!--                    </div>-->
                </div>
              </div>
              <app-top-services class="d-xxl-none"></app-top-services>
            </div>
            <div class="overlay-background"></div>
          </div>
        </div>
        <!-- END SLIDE 2 -->

        <!-- SLIDE 3 -->
        <div class="splide__slide">
            <div class="cover-video"
                 data-background="assets/videos/vid3_snap-min.png"
                 data-jarallax-video="mp4:assets/videos/vid3_1.mp4"
                 style="min-height: 105vh; z-index: 0; background-image: url('assets/videos/vid3_snap-min.png')">

              <div class="container-xl inner-content">
                <div class="row top-heading mobile-heading">
                  <div class="col-sm-12 col-xl-12 mt-0 mt-xl-5">
                    <h1 class="mt-5 mb-3"><strong>Wspieramy <span>sprzedaż</span><br>autorskimi rozwiązaniami<br>dla biznesu</strong></h1>
                    <div class="col-12 col-xl-7 pr-2">
                      <p class="col-12 col-sm-8 col-md-5 col-xl-6"><a class="btn btn-lg btn-block" [routerLink]="['/ebenefit']">Czytaj więcej ></a></p>
                    </div>

                  </div>
                </div>
                <app-top-services class="d-xxl-none"></app-top-services>
              </div>
              <div class="overlay-background"></div>
            </div>
        </div>
        <!-- END SLIDE 3 -->

      </div>

    </div>
  </div>

  <div class="container-xl inner-content">
    <app-top-services class="d-none d-xxl-block xl-services-box" style="margin-right: 10vw"></app-top-services>
  </div>

</section>
