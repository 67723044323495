import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  tracks = [
    'assets/sounds/6.mp3',
    'assets/sounds/7.mp3',
    'assets/sounds/4.mp3'
  ]
  currentTrack: number;
  audio = new Audio();

  constructor() {
    console.log(this.audio)
    this.audio.autoplay = false
    this.audio.loop = true
    this.audio.src = this.tracks[0]
    this.audio.load()
  }

  toggleMusic() {
    this.audio.paused ? this.audio.play() : this.audio.pause()
    return this.audio.paused;
  }

  changeTrack(trackNumber: number): void {
    this.audio.pause();
    this.audio.src = this.tracks[trackNumber]
    this.audio.load()
    this.currentTrack = trackNumber
    this.audio.play()
  }
}
