import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-quick-contact',
  templateUrl: './quick-contact.component.html',
  styleUrls: ['./quick-contact.component.scss']
})
export class QuickContactComponent implements OnInit {

  @ViewChild('email') emailInputRef: ElementRef
  @ViewChild('phone') phoneInputRef: ElementRef
  submitted = false;
  sent = false;
  error = false;
  captchaResolved = false;

  model = new Message('', '', '', '', '');

  public recentToken: string = '';
  public recentError?: { error: any };
  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  onSubmit() {
    this.submitted = true;
    this.http.post('sendMail.php', this.model).subscribe(data => {
      if (data === 'sent') {
        this.sent = true;
      } else if (data === 'error') {
        this.error = true;
      }
    });
  }

  constructor(private http: HttpClient, private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.reCaptchaV3Service.execute(action)
      .subscribe(token => {
        this.model.captcha = token;
        this.recentError = undefined;
        this.onSubmit();
      },
        error => {
        this.model.captcha = '';
        this.recentError = { error };
        })
  }

  get isMailOrPhoneFilled() {
    if (this.emailInputRef.nativeElement.value == '' && this.phoneInputRef.nativeElement.value == '') return false;
  }

  resolved(event) {
    console.log(`Resolved with response: ${event}`)
    this.model.captcha = event;
    this.captchaResolved = true;
  }
}

class Message {
  constructor(
    public name: string,
    public email: string,
    public phone: string,
    public text: string,
    public captcha: string,
  ) {}
}
