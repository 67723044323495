import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import Splide from "@splidejs/splide";
import Video from "@splidejs/splide-extension-video";
import {AudioService} from "../../audio.service";
import { smoothScroll } from "../../../smooth-scroll";


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  isCollapsed = false;
  portfolio;
  scrollingTime = 500;

  // Portfoliobox config
  currentProject = "";
  currentProjectId = null;
  portfolioItem = null;
  picturesSlider = null;

  constructor(private http: HttpClient, private audioService: AudioService) {
    this.http.get('assets/portfolio.json').subscribe(data => {
      this.portfolio = data;
    })
  }

  ngOnInit(): void {
    this.picturesSlider = new Splide('.portfolio-pictures-slider', {
      cover: true,
      type: 'slide',
      clones: 0,
      pagination: true,
      arrows: false,
      autoplay: false,
      heightRatio: 0.75,
      // video: {
      //   playerOptions: {
      //     htmlVideo: {
      //       playsinline: true
      //     }
      //   }
      // }
    }).mount({ Video });

    this.picturesSlider.on('video:play', () => {
      this.audioService.audio.pause();
    })
  }

  showPortfolioItem(name: string, id: number = null) {
    if (name && this.currentProject === name) {
      this.isCollapsed = false;
      this.currentProject = '';
    } else {
      // document.querySelector('.portfolio').scrollIntoView({
      //   behavior: 'smooth'
      // });
      setTimeout(() => {
        smoothScroll(document.querySelector('.portfolio-controls'))
      }, 100)


      this.isCollapsed = true;
      this.currentProject = name;
      this.portfolioItem = this.portfolio.filter(x => id ? x.id === id : x.name === name)[0];
      this.currentProjectId = this.portfolioItem.id;

      var len = this.picturesSlider.length;
      while(len > 0) {
        this.picturesSlider.remove(len - 1);
        len--;
      }
      this.portfolioItem.media.forEach(el => {
        if (el.type === 'pic') {
          this.picturesSlider.add(`<div class="splide__slide pictures-slider-slide" style="margin-bottom: 35px;"><img src="${el.url}" alt=""></div>`)
        } else if (el.type === 'vid') {
          this.picturesSlider.add(`<div class="splide__slide pictures-slider-slide" style="margin-bottom: 35px;" data-splide-html-video="${el.url}">
<img src="${this.portfolioItem.cover}" alt="">
</div>`)
        } else if (el.type === 'ytb') {
          this.picturesSlider.add(`<div class="splide__slide pictures-slider-slide" style="margin-bottom: 35px;" data-splide-youtube="${el.url}">
<img src="${this.portfolioItem.cover}" alt="">
</div>`)
        }
      })
      if (this.portfolioItem.media.length === 1 ) {
        this.picturesSlider.options.pagination = false;
      } else {
        this.picturesSlider.options.pagination = true;
      }
      this.picturesSlider.options.video.playerOptions = {
        htmlVideo: {
          playsinline: true,
        }
      };
      this.picturesSlider.mount({Video});
      this.picturesSlider.on('video:play', () => {
        this.audioService.audio.pause();
      })
    }

  }

  nextPortfolioItem() {
    if (this.currentProjectId && this.currentProjectId < 5) {
      this.showPortfolioItem(null, this.currentProjectId + 1);
    } else {
      this.showPortfolioItem(null, 1);
    }
  }

  prevPortfolioItem() {
    if (this.currentProjectId && this.currentProjectId > 1) {
      this.showPortfolioItem(null, this.currentProjectId - 1);
    } else {
      this.showPortfolioItem(null, 5);
    }
  }

  closePortfolioItem() {
    this.portfolioItem = null;
    this.currentProjectId = null;
    this.currentProject = null;
    this.isCollapsed = false;
    this.picturesSlider.destroy();
  }

  // smoothScroll(element, duration) {
  //   var elementY = window.pageYOffset + element.getBoundingClientRect().top;
  //   var startY = window.pageYOffset;
  //   var diff = elementY - startY;
  //   var start;
  //
  //   window.requestAnimationFrame(function step(timestamp) {
  //     if (!start) start = timestamp;
  //     var time = timestamp - start;
  //     var percent = Math.min(time / duration, 1);
  //     window.scrollTo(0, startY + diff * percent);
  //     if (time < duration) {
  //       window.requestAnimationFrame(step);
  //     }
  //   });
  // }
}
