<section class="portfolio dark">
  <div class="container-xl">

    <div id="portfolio-slider" class="row">
      <div class="col-12 col-lg-6">
        <div class="section-heading">
          <h2>Wybrane prace</h2>
          <h4>Te projekty lubimy szczególnie</h4>
        </div>
      </div>

      <div class="col-lg-6 text-center text-lg-right portfolio-controls collapse justify-content-center align-items-center justify-content-lg-end" [ngbCollapse]="!isCollapsed" [ngClass]="isCollapsed ? 'd-flex' : ''">
        <a (click)="prevPortfolioItem()" class="portfolio-btn prev-portfolio"><img src="assets/images/icons/chevron-left.svg"></a>
        <a (click)="closePortfolioItem()" class="portfolio-btn close-portfolio"><img src="assets/images/icons/x.svg"></a>
        <a (click)="nextPortfolioItem()" class="portfolio-btn next-portfolio"><img src="assets/images/icons/chevron-right.svg"></a>
      </div>

      <div class="col-12 portfolio-items">

        <!-- [Portfolio] Project details -->
        <div id="portfolio-box" class="portfolio-box" [ngbCollapse]="!isCollapsed">
          <div class="row p-0 p-xl-4">
            <div class="description-box-mob order-2 order-xl-1 col-xl-5 d-flex flex-column justify-content-start align-items-start p-3 p-xl-3 pb-xl-0">
              <p class="h2 portfolio-name">{{ portfolioItem?.name }} - {{ portfolioItem?.subname }}</p>
              <p class="tags"><span class="font-weight-bold mr-1">Tagi:</span><span class="portfolio-categories" *ngFor="let category of portfolioItem?.categories; let last = last">{{ category + (!last ? ', ' : '')}}</span></p>
              <div class="portfolio-description" [innerHTML]="portfolioItem?.description"></div>
            </div>
            <div class="col-xl-7 order-1 order-xl-2">
              <div class="splide portfolio-pictures-slider" id="portfolio-pictures-slider">
                <div class="splide__track">
                  <div class="splide__list">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / Project details-->


        <div class="col-12 portfolio-container">

          <!-- [Portfolio] Projects grid -->
          <div *ngFor="let project of portfolio; let index = index" class="col-6 col-xl-3 portfolio-item" style="grid-area: {{ 'a'+ project.id }}" (click)="showPortfolioItem(project.name)">
            <div class="d-none d-xl-flex align-items-center justify-content-center flex-column portfolio-item-cover">
              <span class="mb-2">{{ project.name }}</span>
              <span>{{ project.subname }}</span>
            </div>
            <div class="d-flex d-xl-none portfolio-item-cover-mobile">
              {{ project.name }} - {{ project.subname }}
            </div>
            <img [src]="project.cover" alt="" class="img-fluid">
          </div>
          <!-- / Projects grid -->

        </div>
      </div>
    </div>
  </div>
</section>
