<div class="wrapper">
  <section id="statuette-section">
    <div class="container-fluid mob-black">
      <div class="container">
        <div class="col-12 col-md-8 offset-md-2 d-flex flex-column">
          <p class="h1 mb-5">Digital Direct House wyróżnione srebrną statuetką w MIXX Awards Europe 2020</p>

          <img [src]="'assets/images/statuette/' + statuetteSettings.currentFrame + '.png'" alt="" class="img-fluid statuette d-sm-none mx-auto mb-5">
        </div>

        <div class="col-12 col-md-10 offset-md-1 mb-5">
          <!--        <div id="video-box" data-plyr-provider="youtube" data-plyr-embed-id="GFyJGbqYw3o">-->
          <video id="video-box" playsinline controls data-poster="assets/images/mixx_cover.jpg">
            <source src="assets/videos/Citi%20Simplicity%20Card%20-%20YouTube%20Director%20Mix%20PL.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
    <div class="container mt-3">

      <div class="col-12 col-md-7 offset-md-3">
        <div class="row">
          <div class="col-12 col-md-4">
            <img src="assets/images/mixx_logo.png" class="img-fluid" alt="">
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">MIXX Awards Europe i Research Awards 2020</span> to konkurs, w którym nagradzane są wyjątkowe kampanie marketingowe typu digital – takie, które łączą kreatywne idee z efektywnymi rozwiązaniami i wyznaczają standardy kampanii interaktywnych w Europie.
          </div>
        </div>

        <div>
          <p>Nasza praca dla Citi Handlowy: „Citi Simplicity Card – YouTube Director’s Mix” została nagrodzona Srebrną Statuetką w kategorii <span class="font-weight-bold">Video Advertising</span>.</p>
          <p>Mieliśmy przyjemność współprodukować tę kampanię jako pierwsi w Polsce oraz jedni z pierwszych na świecie przy współpracy z Feno, Sales&More oraz Google. Naszym głównym zadaniem było stworzenie struktury platformy video do kampanii i osadzenie jej w silniku Google You Tube Director Mix. To bardzo wymagająca praca, łącząca nie tylko zaawansowaną wiedzę z produkcji video ale również przekładająca zachowanie użytkownika w logiczny łańcuch zdarzeń, poszczególnych rekordów z bazy danych, tworzące w efekcie końcowym customizowane video.</p>
        </div>
      </div>

      <div class="col-12 col-md-10 offset-md-1 my-5">
        <img src="assets/images/mixx-page/img1.jpeg" alt="" class="img-fluid">
      </div>

      <div class="col-12 col-md-8 offset-md-2">

        <div class="mb-5">
          <div class="row">
            <div class="col-10 offset-1 offset-md-0 col-md-6">
              <p class="h2 mb-4">Zachowanie użytkownika w Internecie to początek dla zaawansowanych technologii</p>
            </div>
            <div class="col-10 offset-1 offset-md-0 col-md-6">
              <p>Użytkownik, po wpisaniu frazy w wyszukiwarkach Google początkował cały proces technologiczny. Na tej bazie wiedzy, dopasowywaliśmy konkretne sekwencje oddzielnych plików audio, video oraz tekstów, tworząc w ten sposób jeden film, dopasowany do jego profilu. Na przykład: użytkownik wyszukiwał w Google „jak wykonać pizzę w domu”.</p>
              <p>Silnik identyfikował i zapamiętywał jego zachowanie, a gdy korzystał z YouTube wyświetlał mu film:
                Masz ochotę na włoskie jedzenie? Skorzystaj ze zniżki do 20% w wybranych restauracjach płacąc kartą kredytową Citi Simplicity. W przypadku, gdy szukał informacji o ulubionej muzyce, adekwatnie otrzymywał film: Lubisz dobrą muzykę? Korzystaj z voucherów na festiwale muzyczne, płacąc kartą Citi Simplicity.
              </p>
            </div>
          </div>


        </div>

        <div>
          <div class="row">
            <div class="col-10 offset-1 offset-md-0 col-md-6">
              <p class="h2 mb-4">Sukces tkwi w szczegółach</p>
            </div>
            <div class="col-10 offset-1 offset-md-0 col-md-6">
              <p>Aby osiągnąć możliwość generowania customizowanych video wpisanych w założenia sprzedażowe produktu Citi, podzieliliśmy video na 4 sekcje: Dopasowanie, Benefit, Narzędzie oraz CTA. Na to wszystko nałożyliśmy muzykę, odpowiednie teksty czytane przez lektora, treści wyświetlane w filmie oraz bloki video w odpowieniej kolejności. Wydaje się proste? Tylko w Excelu spędziliśmy ponad 2 miesiące, a „na produkcji” kolejny miesiąc. Zobaczcie „od kuchni” efekt naszych spotkań kreatywnych i produkcyjnych, na podstawie którego stworzyliśmy finalną strukturę projektu. </p>
            </div>
          </div>


        </div>
      </div>

      <div class="col-12 col-md-10 offset-md-1 my-5">
        <img src="assets/images/mixx-page/img2.jpeg" alt="" class="img-fluid">
      </div>

      <div class="col-12 col-md-8 offset-md-2">
        <div class="row">
          <div class="col-10 offset-1 offset-md-0 col-md-6">
            <p class="h2 mb-4">#DDHTeam<br>Digital mamy w DNA</p>
          </div>
          <div class="col-10 offset-1 offset-md-0 col-md-6">
            <p>Ten projekt skupiał w sobie wiele różnorodnych i skrajnych kompetencji: kreacja, bazy danych, produkcja, programowanie, planowanie mediów, strategia, automatyzacja procesów. Uczestniczenie w tak zaawansowanym i ciekawym projekcie potwierdziło nasze zamiłowania do poszukiwania nieoczywistych i skutecznych rozwiązań w digitalu. Nasz wewnętrzny mix osobowości, z różnymi doświadczeniami i kompetencjami dał nam MIXX Awards 2020. Bardzo nas to cieszy, chcemy więcej!</p>
          </div>
        </div>


      </div>

    </div>
  </section>

  <section class="py-5"><a class="text-center text-decoration-underline back" [routerLink]="'/'" data-scroll-to=".header-section">Powrót do strony głównej</a></section>

  <section class="prefooter jarallax">
    <img class="jarallax-img" src="assets/images/prefooter.png" alt="">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 d-md-none">
          <h2>Warto zacząć od krótkiego maila</h2>
        </div>
        <div class="col-12 offset-0 text-right col-md-8 offset-md-2">
          <a href="mailto:info@ddh.pl" class="d-none d-md-block btn btn-lg btn-block btn-secondary">Warto zacząć od krótkiego maila. Porozmawiajmy!</a>
          <a href="mailto:info@ddh.pl" class="d-md-none btn btn-lg btn-block btn-secondary">Porozmawiajmy!</a>
        </div>
      </div>
    </div>
  </section>
</div>

