import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MainPageComponent } from './main-page/main-page.component';
import { EbenefitPageComponent } from './ebenefit-page/ebenefit-page.component';
import { FooterComponent } from './footer/footer.component';
import { PortfolioComponent } from './main-page/portfolio/portfolio.component';
import { QuickContactComponent } from './main-page/quick-contact/quick-contact.component';
import { HeaderSliderComponent } from './main-page/header-slider/header-slider.component';
import { ControlsComponent } from './navbar/controls/controls.component';
import {FormsModule} from "@angular/forms";
import { TopServicesComponent } from './main-page/top-services/top-services.component';
import { MixxPageComponent } from './mixx-page/mixx-page.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module} from "ng-recaptcha";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MainPageComponent,
    EbenefitPageComponent,
    FooterComponent,
    PortfolioComponent,
    QuickContactComponent,
    HeaderSliderComponent,
    ControlsComponent,
    TopServicesComponent,
    MixxPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaV3Module
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LfESc0ZAAAAAGizlJCPKE2JylY0pkfWGmixPiTy' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
