<header id="header" class="header header-transparent">
  <div class="container-xl">
    <div class="inner-header d-flex justify-content-center align-items-center">
      <a class="inner-brand" href="" [routerLink]="'/'" data-scroll-to=".header-section"></a>
    </div>

    <div class="inner-navigation collapse">
      <div class="inner-nav">
        <ul>
          <li><a href="" data-scroll-to=".portfolio" [routerLink]="'/'" (click)="onClick()"><span>Wybrane prace</span></a></li>
          <li><a href="" data-scroll-to=".about-us" [routerLink]="'/'" (click)="onClick()"><span>O nas</span></a></li>
          <li class="quick-contact d-none d-xl-block"><a href=""><span>Kontakt</span></a></li>
          <li class="phoneinmenu"><a href="tel:48730108403" (click)="onClick()"><span>+48 730 10 84 03</span></a></li>
          <li class="d-lg-none"><a href="mailto:info@ddh.pl" (click)="onClick()"><span>info@ddh.pl</span></a></li>
        </ul>
      </div>
    </div>

    <div class="extra-nav">
      <ul>
        <li class="top-bar-phone"><a href="tel:48730108403"><span>+48 730 10 84 03</span></a></li>
        <div class="inner-header d-flex justify-content-center align-items-center mx-3">
          <a target="_blank" href="https://pl-pl.facebook.com/DigitalDirectHouse/" class="social"><img src="assets/images/facebook.svg" alt=""></a>
          <a target="_blank" href="https://www.youtube.com/channel/UCWQHpYpcuawrSBcnJc60OfQ" class="social"><img src="assets/images/youtube.svg" alt=""></a>
          <a target="_blank" href="https://m.me/DigitalDirectHouse" class="social"><img src="assets/images/messenger.svg" alt=""></a>
        </div>
        <li class="nav-toggle">
          <a data-toggle="collapse" data-target=".inner-navigation" class="hamburger hamburger--minus" aria-expanded="false">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>
