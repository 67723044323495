<section id="footer" class="footer">
  <div class="container-xl">
    <div class="row">
      <div class="col-6 col-lg-4">
        <aside class="widget">
          <div class="widget-title">
            <h6>Say hello</h6>
          </div>
          <div class="widget-content">
            <p>Digital Direct House sp. z o.o.<br>
              ul. Jodłowa 27/3<br>
              02-907 Warszawa</p>
            <p>NIP: 5213608538</p>
          </div>
        </aside>
      </div>
      <div class="col-6 col-lg-4">
        <aside class="widget">
          <div class="widget-title">
            <h6>Kontakt</h6>
          </div>
          <div class="widget-content">
            <p><a href="mailto:info@ddh.pl" class="text-decoration-none">info@ddh.pl</a><br>
              <a href="tel:48730108403" class="text-decoration-none">+48 730 10 84 03</a>
            </p>
          </div>
        </aside>
      </div>
      <div class="col-12 col-lg-4 text-center text-lg-right">
        <h6>Digital Direct House © 2020</h6>
      </div>
    </div>
  </div>
</section>
