<div class="row top-services">
  <div class="col-12 col-sm-6 col-xxl-3">
    <div class="infobox">
      <div class="infobox-icon">
        <div class="icon-wrap">
          <img src="assets/images/icons/sales_support.svg" alt="">
        </div>
      </div>
      <div class="infobox-content">
        <h3 class="title">Sales Support</h3>
        <p>Tworzymy inteligentne rozwiązania, wspierające biznes Klientów. Rozumiemy ich środowiska korporacyjne. Łączymy zarządzanie centralne z przepływami informacji oraz końcowym beneficjentem.</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xxl-3">
    <div class="infobox">
      <div class="infobox-icon">
        <div class="icon-wrap">
          <img src="assets/images/icons/mobile_solutions.svg" alt="">
        </div>
      </div>
      <div class="infobox-content">
        <h3 class="title">Mobile Solutions</h3>
        <p>Produkujemy aplikacje oraz strony dedykowane rozwiązaniom mobilnym. Prowadzimy kompleksowy proces od ideii, designu, dopasowania technologii, kodowania oraz wdrożenia.</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xxl-3">
    <div class="infobox">
      <div class="infobox-icon">
        <div class="icon-wrap">
          <img src="assets/images/icons/digital_signage.svg" alt="">
        </div>
      </div>
      <div class="infobox-content">
        <h3 class="title">Digital Signage</h3>
        <p>Wspieramy sieci oraz indywidualne instalacje display'owe. Dopasowujemy własny silnik do indywidualnych potrzeb odbiorcy, miksując go z różnorodnymi technologiami.</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xxl-3">
    <div class="infobox">
      <div class="infobox-icon">
        <div class="icon-wrap">
          <img src="assets/images/icons/video_for_online.svg" alt="">
        </div>
      </div>
      <div class="infobox-content">
        <h3 class="title">Video for online</h3>
        <p>Pomagamy kreować video do tzw. digital’a. Specjalizujemy się w video interaktywnym i sekwencyjnym. Prowadzimy proces, od pomysłu, scenariusza, produkcje po wdrożenie.</p>
      </div>
    </div>
  </div>
</div>
