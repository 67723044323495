import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainPageComponent} from "./main-page/main-page.component";
import {EbenefitPageComponent} from "./ebenefit-page/ebenefit-page.component";
import {MixxPageComponent} from "./mixx-page/mixx-page.component";

const routes: Routes = [
  { path: 'ebenefit', component: EbenefitPageComponent },
  { path: 'mixx', component: MixxPageComponent },
  { path: '**', component: MainPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
