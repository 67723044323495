<div class="wrapper">

  <app-header-slider></app-header-slider>

  <section class="clients">
    <div class="container-xl">
      <div class="row">
        <div id="clinet-slider" class="splide">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide"><img src="assets/images/partners/19.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/20.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/21.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/1.png" class="img-fluid" alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/2.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/3.png"  class="img-fluid" alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/4.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/5.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/6.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/7.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/8.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/9.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/10.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/11.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/13.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/14.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/15.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/16.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/17.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/18.png" class="img-fluid"  alt=""></li>
              <li class="splide__slide"><img src="assets/images/partners/23.png" class="img-fluid"  alt=""></li>
         
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-portfolio></app-portfolio>

  <section class="about-us">
    <div class="container-xl">

      <div class="row d-flex align-items-center">
        <div class="col-12">
          <div class="section-heading">
            <h2>O nas</h2>
            <h4 class="subtitle">#DDHTEAM to grupa pasjonatów i po prostu fajnych ludzi</h4>
          </div>
        </div>
      </div>

      <div class="gallery">
        <div class="big"> </div>
        <div class="small_top"> </div>
        <div class="small_bottom"> </div>
      </div>


      <div class="row">
        <div class="col-12 col-md-4">
          <h4>Kim jesteśmy?</h4>
          <p>Digital Direct House powstał w 2011 roku. Od początku istnienia specjalizowaliśmy się w obszarach digital marketingu i nowych technologii. Jesteśmy projektantami, myślicielami, strategami, ludźmi biznesu. Często zabawni, zawsze kreatywni.</p>
        </div>
        <div class="col-12 col-md-4">
          <h4>Co robimy?</h4>
          <p>Z pasją tworzymy i rozwijamy komunikację brandów w digitalu. Staramy się aby każde nasze działanie zapewniało zwrot z inwestycji i rezonowało z grupą odbiorców. Staramy się wyprzedzać główny nurt, szukamy nowych i inspirujących rozwiązań.</p>
        </div>
        <div class="col-12 col-md-4">
          <h4>Nasza filozofia</h4>
          <p>Dbamy o detale, rozważamy różne warianty i scenariusze, wspieramy biznes naszych Klientów. Traktujemy nasze projekty poważnie. Wkładamy w nie serce, kreatywność i pozytywne emocje. Chcemy, wspólnie z Klientem, mieć poczucie twórczej i potrzebnej pracy.</p>
        </div>
      </div>


    </div>
  </section>

  <section class="prefooter jarallax">
    <img class="jarallax-img" src="assets/images/prefooter.png" alt="">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 d-md-none">
          <h2>Warto zacząć od krótkiego maila</h2>
        </div>
        <div class="col-12 offset-0 text-right col-md-8 offset-md-2">
          <a href="mailto:info@ddh.pl" class="d-none d-md-block btn btn-lg btn-block btn-secondary">Warto zacząć od krótkiego maila. Porozmawiajmy!</a>
          <a href="mailto:info@ddh.pl" class="d-md-none btn btn-lg btn-block btn-secondary">Porozmawiajmy!</a>
        </div>
      </div>
    </div>
  </section>
</div>
