import { Component, OnInit } from '@angular/core';
import {smoothScroll} from "../../smooth-scroll";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

    window.addEventListener('scroll', function () {
      var header = document.getElementById("header");
      var scrollpos = window.scrollY;

      if (scrollpos > 10) {
        header.classList.add("header-small");
      } else {
        header.classList.remove("header-small");
      }
    });

    var menuLinks = document.querySelectorAll("a[data-scroll-to]");

    menuLinks.forEach(elem => {
      var el = elem as HTMLElement;
      el.addEventListener('click', evt => {
        evt.preventDefault();
        var targetEl = document.querySelector(el.dataset.scrollTo);
        // targetEl.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'center'
        // });
        smoothScroll(targetEl);
      })
    })
  }

  onClick() {
    document.querySelector('.hamburger').classList.remove('is-active');
  }
}
