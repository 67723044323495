<div id="quick-contact" class="d-none qc d-xl-block py-5">
  <p class="h2 font-weight-bold d-flex align-items-center"><span>Szybki kontakt</span> <span class="box-close ml-auto text-right"><img class="box-close" src="assets/images/icons/x.svg"></span></p>

  <div class="errors">
    <div [hidden]="name.valid || name.pristine">⚠ Sprawdź wpisane imię i nazwisko</div>
    <div [hidden]="email.valid || email.pristine">⚠ Sprawdź wpisany adres e-mail</div>
    <div [hidden]="(email.value !== '' || phone.value !== '') || (email.pristine && phone.pristine)">⚠ Podaj e-mail <i>lub</i> numer telefonu</div>
  </div>

  <form #messageForm="ngForm" (ngSubmit)="executeAction('send_contact')">
    <input type="text"
           placeholder="Imie i nazwisko *"
           required
           [(ngModel)]="model.name"
           name="name"
           #name="ngModel"
    >
    <input type="email"
           placeholder="Twój adres e-mail *"
           [(ngModel)]="model.email"
           name="email"
           #email="ngModel"
           required
           pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    >
    <input type="text"
           placeholder="Twój nr telefonu"
           [(ngModel)]="model.phone"
           name="phone"
           #phone="ngModel"
    >
    <textarea rows="8"
              placeholder="Wiadomość *"
              [(ngModel)]="model.text"
              name="text"
              required
              #text="ngModel"
    ></textarea>
<!--    <re-captcha (resolved)="resolved($event)" siteKey="6LeDAc0ZAAAAAHxSKzDBVTfVhQFVco76Da2NwpHT"></re-captcha>-->
    <button class="btn btn-lg btn-block btn-secondary mt-3"
            type="submit"
            [disabled]="(!messageForm.form.valid || !(email.value !== '' || phone.value !== ''))"
    >
      <span *ngIf="!sent && !submitted && !error">Wyślij</span>
      <span *ngIf="!sent && !error && submitted">Wysyłam...</span>
      <span *ngIf="sent">Wiadomość wysłana pomyślnie</span>
      <span *ngIf="error">Błąd podczas wysyłania wiadomości</span>
    </button>

    <small class="mt-3 d-inline-block">* pole wymagane</small><br>
    <small style="font-size: 12px; font-weight: 300;">This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
  </form>
</div>
