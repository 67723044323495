import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-services',
  templateUrl: './top-services.component.html',
  styleUrls: ['./top-services.component.scss']
})
export class TopServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
