<div class="inner-header d-none d-xl-flex justify-content-center align-items-center mx-3 controls flex-column">

  <label for="sound-switch" class="sound-switch">
    <input type="checkbox" name="sound-switch" id="sound-switch" (click)="toggleMusic()" [checked]="!audioPaused">
    <span class="round"></span>
  </label>

  <div class="d-flex mt-3">
    <span class="square" [ngClass]="{'active': currentTrack === 0}" (click)="changeTrack(0)"></span>
    <span class="square" [ngClass]="{'active': currentTrack === 1}" (click)="changeTrack(1)"></span>
    <span class="square" [ngClass]="{'active': currentTrack === 2}" (click)="changeTrack(2)"></span>
  </div>
</div>
