import { Component, OnInit } from '@angular/core';
import * as Plyr from "plyr";
import { jarallax } from 'jarallax';
import {smoothScroll} from "../../smooth-scroll";

@Component({
  selector: 'app-mixx-page',
  templateUrl: './mixx-page.component.html',
  styleUrls: ['./mixx-page.component.scss']
})
export class MixxPageComponent implements OnInit {

  plyr: Plyr;

  statuetteSettings = {
    scrollDistance: 600,
    frameCount: 8,
    pixelsPerFrame: 75,
    currentFrame: 1,
  }

  constructor() { }

  ngOnInit(): void {
    var header = document.getElementById("header");
    header.classList.add('page-nav')
    this.plyr = new Plyr('#video-box');

    console.log("oninit mixx")
    var offsetfooter = document.getElementById('footer').offsetHeight;
    document.body.style.marginBottom = offsetfooter - 5 + "px";

    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
      disableParallax: /iPad|iPhone|iPod|Android/,
      disableVideo: /iPad|iPhone|iPod|Android/
    });

    document.addEventListener('scroll', e => {
      this.statuetteSettings.currentFrame = Math.ceil(window.pageYOffset / this.statuetteSettings.pixelsPerFrame);
      if (this.statuetteSettings.currentFrame === 0) this.statuetteSettings.currentFrame++;
      if (this.statuetteSettings.currentFrame > this.statuetteSettings.frameCount) this.statuetteSettings.currentFrame = this.statuetteSettings.frameCount;
      // document.querySelector('.statuette').src = `assets/images/statuette/${currentFrame}.png`
    })

    var menuLinks = document.querySelectorAll("a[data-scroll-to]");

    menuLinks.forEach(elem => {
      var el = elem as HTMLElement;
      el.addEventListener('click', evt => {
        evt.preventDefault();
        var targetEl = document.querySelector(el.dataset.scrollTo);
        // targetEl.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'center'
        // });
        smoothScroll(targetEl);
      })
    })
  }

}
