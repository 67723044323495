<div class="wrapper">
  <section>
    <div class="container mt-5">
      <div class="col-12 col-md-8 offset-md-2">
        <div class="row">
          <div class="col-10 offset-1 offset-md-0 col-md-6">
            <p class="h1">Wspieraj sprzedaż zautomatyzowanym rozwiązaniem</p>
          </div>
          <div class="col-10 offset-1 offset-md-0 col-md-6">
            <p>Posiadamy autorskie rozwiązanie software'owe - eBenefit, dzięki któremu zarządzanie aktywnością sprzedaży jest zintegrowane i prostsze. Bezpośrednia komunikacja z użytkownikami wewnątrz systemu, wpływa na ich naturalnie większe zaangażowanie i buduje trwałą relację.</p>
            <p>Silnik eBenefit zbudowany jest na nowych technologiach: Angular, Mongo DB, Amazon AWS, które dają szybkość, swobodę w działaniu, możliwość nieintruzywnej rozbudowy i modyfikacji, bezpieczeństwo oraz rozsądne koszty utrzymania.</p>
            <p>Centrala biznesu Klienta zostaje wyposażona w panel zarządzania w wersji desktop, a użytkownik końcowy dedykowaną aplikację mobilną. Bo na "komórce", zawsze pod ręką! Aplikację dewelopujemy na środowiskach Android i iOS.</p>
          </div>
        </div>


      </div>
    </div>
  </section>

  <section class="pt-0">
    <div class="d-none d-md-flex container-fluid" id="section-a">
<!--      <div class="container">-->
<!--        <div class="col-12 col-md-8 offset-md-2 d-flex flex-column py-5 section-content">-->
      <div class="list-container">
          <p class="title h3 font-weight-bold p-3">Dzięki eBenefit zyskujesz:</p>
          <ul class="list p-4 m-0">
            <li>Wsparcie przepływu procesów sprzedażowych</li>
            <li>Monitorowanie realizacji targetów oraz aktywności sprzedawców</li>
            <li>Bezpośrednią, interaktywną komunikację ze sprzedawcą 24/7</li>
            <li>Spersonalizowany program bonusowy</li>
            <li>Lepszą i aktualną wiedzę o produktach Twoich handlowców</li>
            <li>Szybką, zwrotną informację rynkową</li>
          </ul>
      </div>
<!--      </div>-->
      </div>

    <div class="d-block d-md-none" id="section-a-mob">
      <img src="assets/images/ebenefit-page/image1.png" alt="" class="img-fluid">
      <div class="container list-container-mob">
        <div class="col-12">
          <div class="row">
            <div class="col-10 offset-1 pt-5 pt-md-0">
              <p class="h1 mt-3">Dzięki eBenefit zyskujesz:</p>
              <ul class="list py-3 pl-1 m-0">
                <li>Wsparcie przepływu procesów sprzedażowych</li>
                <li>Monitorowanie realizacji targetów oraz aktywności sprzedawców</li>
                <li>Bezpośrednią, interaktywną komunikację ze sprzedawcą 24/7</li>
                <li>Spersonalizowany program bonusowy</li>
                <li>Lepszą i aktualną wiedzę o produktach Twoich handlowców</li>
                <li>Szybką, zwrotną informację rynkową</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <section class="p-0 section-b-wrap">
<!--    <div class="container-fluid py-3 py-md-0" id="section-b">-->
<!--      <div class="container">-->
<!--        <div class="col-12 col-md-8 offset-md-2">-->
<!--          <p class="h3 title p-3">Dlaczego warto?</p>-->
<!--          <ul class="list col-12 col-md-6 offset-md-4 p-4 m-0 mt-5">-->
<!--            <li>Pełna customizacja produktu.</li>-->
<!--            <li>Moduł zarządzania programem dopasowany do profilu biznesu i indywidualnych potrzeb.</li>-->
<!--            <li>Bezpieczna integracja z API Klienta (przepływ danych on-line).</li>-->
<!--            <li>Dopasowanie mechanizmów i funkcjonalności do polityki firmy oraz celów strategicznych.</li>-->
<!--            <li>łatwe zarządzanie i kreowanie zadań z poziomu panelu (drzewo loginów dostępowych).</li>-->
<!--            <li>Monitoring 360, dane aktualizowane on-line.</li>-->
<!--            <li>Możliwość segmentacji i relewancji eventów (wiele luster programu dopasowanych pod specyfikę grup odbiorców.</li>-->
<!--            <li>Zoptymalizowana aplikacja (UX i UI).</li>-->
<!--            <li>Zautomatyzowana komunikacja z użytkownikami: wiadomości push, sms, video, e-mailing.</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="container-fluid">
      <div class="row section-b">
        <div class="order-2 order-md-1 col-12 col-md-6">
          <div class="row">
            <div class="col-10 offset-1 col-md-6 offset-md-5 pt-5 pt-md-0">
              <p class="h1 mt-3">Dlaczego warto?</p>
              <ul class="list py-3 pl-1 m-0">
                <li>Pełna customizacja produktu.</li>
                <li>Moduł zarządzania programem dopasowany do profilu biznesu i indywidualnych potrzeb.</li>
                <li>Bezpieczna integracja z API Klienta (przepływ danych on-line).</li>
                <li>Dopasowanie mechanizmów i funkcjonalności do polityki firmy oraz celów strategicznych.</li>
                <li>łatwe zarządzanie i kreowanie zadań z poziomu panelu (drzewo loginów dostępowych).</li>
                <li>Monitoring 360, dane aktualizowane on-line.</li>
                <li>Możliwość segmentacji i relewancji eventów (wiele luster programu dopasowanych pod specyfikę grup odbiorców.</li>
                <li>Zoptymalizowana aplikacja (UX i UI).</li>
                <li>Zautomatyzowana komunikacja z użytkownikami: wiadomości push, sms, video, e-mailing.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="order-1 order-md-2 col-12 col-md-6 photo-b"></div>
      </div>
    </div>
  </section>

  <section class="p-0">
    <!--    <div class="container-fluid">-->
    <!--      <div class="container">-->
    <!--        <div class="col-12 col-md-6 offset-md-2">-->
    <!--          <p class="h3 text-center">Aplikacja dla użytkownika końcowego</p>-->
    <!--          <ul class="list p-4 m-0 mt-5 left-list">-->
    <!--            <li>Indywidualne harmonogramy, zadania, targety.</li>-->
    <!--            <li>Agregacja aktywności (wyniki, ranking).</li>-->
    <!--            <li>Biblioteka danych: katalogi produktowe, regulaminy, wiadomości, video.</li>-->
    <!--            <li>Moduł e-learningu, ankiet, video prezentacji.</li>-->
    <!--            <li>Quizów (wykorzystujemy techniki grywalizacji).</li>-->
    <!--            <li>W pełni funkcjonalny sklep.</li>-->
    <!--            <li>Postęp aktywności on-line.</li>-->
    <!--            <li>Generator indywidualnych, czasowych QR code'ów dostępowych.</li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->
    <div class="container-fluid">
      <div class="row section-b right-section">
        <div class="order-2 order-md-2 col-12 col-md-6">
          <div class="row">
            <div class="col-10 offset-1 col-md-6 pt-5">
              <p class="h1 mt-3 title-section ">Aplikacja dla użytkownika końcowego</p>
              <ul class="list py-3 pl-1 m-0">
                <li>Indywidualne harmonogramy, zadania, targety.</li>
                <li>Agregacja aktywności (wyniki, ranking).</li>
                <li>Biblioteka danych: katalogi produktowe, regulaminy, wiadomości, video.</li>
                <li>Moduł e-learningu, ankiet, video prezentacji.</li>
                <li>Quizów (wykorzystujemy techniki grywalizacji).</li>
                <li>W pełni funkcjonalny sklep.</li>
                <li>Postęp aktywności on-line.</li>
                <li>Generator indywidualnych, czasowych QR code'ów dostępowych.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="order-1 order-md-1 col-12 col-md-6 photo-d"></div>
      </div>
    </div>
  </section>

  <section class="pt-0 section-c-wrap">
<!--    <div class="container-fluid py-3 py-md-0" id="section-c">-->
<!--        <div class="container">-->
<!--          <div class="col-12 col-md-6 offset-md-4">-->
<!--            <p class="h3 title text-md-right p-3 mr-md-5 pr-md-5">Panel zarządzania</p>-->
<!--            <ul class="list p-4 m-0 mt-5">-->
<!--              <li>Quick Overview - najważniejsze KPI.</li>-->
<!--              <li>Definicja celów, targetów dla wybranych segmentów, grup.</li>-->
<!--              <li>Definiowanie założeń akcji sprzedażowych w oparciu o parametry produktów, czas, benefity</li>-->
<!--              <li>Seryjne wiadomości SMS, Push.</li>-->
<!--              <li>Kontrola agregatów danych (np.: ilości wizyt, stanów magazynu, produktów promocyjnych, logistyki promotorów).</li>-->
<!--              <li>Zarządzanie użytkownikami programu.</li>-->
<!--              <li>Generowanie raportów.</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    <div class="container-fluid">
      <div class="row section-b">
        <div class="order-2 order-md-1 col-12 col-md-6">
          <div class="row">
            <div class="col-10 offset-1 col-md-6 offset-md-5 pt-5 ">
              <p class="h1 mt-3">Panel zarządzania</p>
              <ul class="list py-3 pl-1 m-0">
                <li>Quick Overview - najważniejsze KPI.</li>
                <li>Definicja celów, targetów dla wybranych segmentów, grup.</li>
                <li>Definiowanie założeń akcji sprzedażowych w oparciu o parametry produktów, czas, benefity</li>
                <li>Seryjne wiadomości SMS, Push.</li>
                <li>Kontrola agregatów danych (np.: ilości wizyt, stanów magazynu, produktów promocyjnych, logistyki promotorów).</li>
                <li>Zarządzanie użytkownikami programu.</li>
                <li>Generowanie raportów.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="order-1 order-md-2 col-12 col-md-6 photo-c"></div>
      </div>
    </div>
  </section>

  <section class="py-5"><a class="text-center text-decoration-underline back" [routerLink]="'/'" data-scroll-to=".header-section">Powrót do strony głównej</a></section>
</div>

<section class="prefooter jarallax">
  <img class="jarallax-img" src="assets/images/prefooter.png" alt="">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-12 d-md-none">
        <h2>Warto zacząć od krótkiego maila</h2>
      </div>
      <div class="col-12 offset-0 text-right col-md-8 offset-md-2">
        <a href="mailto:info@ddh.pl" class="d-none d-md-block btn btn-lg btn-block btn-secondary">Warto zacząć od krótkiego maila. Porozmawiajmy!</a>
        <a href="mailto:info@ddh.pl" class="d-md-none btn btn-lg btn-block btn-secondary">Porozmawiajmy!</a>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
