import { Component, OnInit } from '@angular/core';
import {smoothScroll} from "../../smooth-scroll";

@Component({
  selector: 'app-ebenefit-page',
  templateUrl: './ebenefit-page.component.html',
  styleUrls: ['./ebenefit-page.component.scss']
})
export class EbenefitPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var header = document.getElementById("header");
    header.classList.add('page-nav')
    var offsetfooter = document.getElementById('footer').offsetHeight;
    document.body.style.marginBottom = offsetfooter - 5 + "px";

    var menuLinks = document.querySelectorAll("a[data-scroll-to]");

    menuLinks.forEach(elem => {
      var el = elem as HTMLElement;
      el.addEventListener('click', evt => {
        evt.preventDefault();
        var targetEl = document.querySelector(el.dataset.scrollTo);
        // targetEl.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'center'
        // });
        smoothScroll(targetEl);
      })
    })
  }

}
