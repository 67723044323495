import {AfterViewChecked, AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit() {

    setTimeout(() => {
      let loader = this.renderer.selectRootElement('#loader');
      this.renderer.setStyle(loader, 'display', 'none');
    }, 2000)
  }

}
