const SCROLLING_DURATION = 400;
const SCROLLING_OFFSET = 60;

export function smoothScroll(element, offset = SCROLLING_OFFSET, duration = SCROLLING_DURATION) {
  // setTimeout(()=>{}, 100);
  var elementY = window.pageYOffset + element.getBoundingClientRect().top - offset;
  var startY = window.pageYOffset;
  var diff = elementY - startY;
  var start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    var time = timestamp - start;
    var percent = Math.min(time / duration, 1);
    window.scrollTo(0, startY + diff * percent);
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}
